import { useGetBelanjaitemsQuery } from "../belanjaitems/belanjaitemsApiSlice"
import Belanjaitem from "./Belanjaitem"
// import useAuth from "../../hooks/useAuth"
import useAuth from "../../../hooks/useAuth"
import useTitle from "../../../hooks/useTitle"
import PulseLoader from 'react-spinners/PulseLoader'

const BelanjaitemsList = () => {
    useTitle('techBelanjaitems: Belanjaitems List')

    const { username, isManager, isAdmin } = useAuth()

    const {
        data: belanjaitems,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetBelanjaitemsQuery('belanjaitemsList', {
        pollingInterval: 15000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })

    let content

    if (isLoading) content = <PulseLoader color={"#FFF"} />

    if (isError) {
        content = <p className="errmsg">{error?.data?.message}</p>
    }

    if (isSuccess) {
        const { ids, entities } = belanjaitems

        let filteredIds
        if (isManager || isAdmin) {
            filteredIds = [...ids]
        } else {
            filteredIds = ids.filter(belanjaitemId => entities[belanjaitemId].username === username)
        }

        const tableContent = ids?.length && filteredIds.map(belanjaitemId => <Belanjaitem key={belanjaitemId} belanjaitemId={belanjaitemId} />)

        content = (
            <table className="table table--belanjaitems">
                <thead className="table__thead">
                    <tr>
                        <th scope="col" className="table__th belanjaitem__created">_id</th>
                        <th scope="col" className="table__th belanjaitem__created">name</th>
                        <th scope="col" className="table__th belanjaitem__updated">quantity</th>
                        <th scope="col" className="table__th belanjaitem__title">unit</th>
                        <th scope="col" className="table__th belanjaitem__title">harga</th>
                        <th scope="col" className="table__th belanjaitem__username">toko</th>
                        <th scope="col" className="table__th belanjaitem__status">Username</th>
                        <th scope="col" className="table__th belanjaitem__edit">Edit</th>
                    </tr>
                </thead>
                <tbody>
                    {tableContent}
                </tbody>
            </table>
        )
    }

    return content
}
export default BelanjaitemsList
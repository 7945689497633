import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons"
import { useNavigate } from 'react-router-dom'
import { useGetKulkassectionsQuery } from './kulkassectionsApiSlice'
import { memo } from 'react'

const Kulkassection = ({ kulkassectionId }) => {

    const { kulkassection } = useGetKulkassectionsQuery("kulkassectionsList", {
        selectFromResult: ({ data }) => ({
            kulkassection: data?.entities[kulkassectionId]
        }),
    })

    const navigate = useNavigate()

    if (kulkassection) {
        const created = new Date(kulkassection.createdAt).toLocaleString('en-US', { day: 'numeric', month: 'long' })

        const updated = new Date(kulkassection.updatedAt).toLocaleString('en-US', { day: 'numeric', month: 'long' })

        const handleEdit = () => navigate(`/dash/kulkassections/${kulkassectionId}`)

        return (
            <tr className="table__row">
                <td className="table__cell kulkassection__created">{kulkassection._id}</td>
                <td className="table__cell kulkassection__created">{kulkassection.name}</td>
                <td className="table__cell kulkassection__updated">{kulkassection.description}</td>
                <td className="table__cell kulkassection__username">{kulkassection.username}</td>

                <td className="table__cell">
                    <button
                        className="icon-button table__button"
                        onClick={handleEdit}
                    >
                        <FontAwesomeIcon icon={faPenToSquare} />
                    </button>
                </td>
            </tr>
        )

    } else return null
}

const memoizedKulkassection = memo(Kulkassection)

export default memoizedKulkassection
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons"
import { useNavigate } from 'react-router-dom'
import { useGetBelanjaitemsQuery, useGetBelanjaitemsitemsQuery } from '../belanjaitems/belanjaitemsApiSlice'
import { memo } from 'react'

const Belanjaitem = ({ belanjaitemId }) => {

    const { belanjaitem } = useGetBelanjaitemsQuery("belanjaitemsList", {
        selectFromResult: ({ data }) => ({
            belanjaitem: data?.entities[belanjaitemId]
        }),
    })

    const navigate = useNavigate()

    if (belanjaitem) {
        const created = new Date(belanjaitem.createdAt).toLocaleString('en-US', { day: 'numeric', month: 'long' })

        const updated = new Date(belanjaitem.updatedAt).toLocaleString('en-US', { day: 'numeric', month: 'long' })

        const handleEdit = () => navigate(`/dash/belanjaitems/${belanjaitemId}`)

        return (
            <tr className="table__row">
                <td className="table__cell belanjaitem__created">{belanjaitem._id}</td>
                <td className="table__cell belanjaitem__created">{belanjaitem.name}</td>
                <td className="table__cell belanjaitem__updated">{belanjaitem.quantity}</td>
                <td className="table__cell belanjaitem__title">{belanjaitem.unit}</td>
                <td className="table__cell belanjaitem__title">{belanjaitem.harga}</td>
                <td className="table__cell belanjaitem__username">{belanjaitem.toko}</td>
                <td className="table__cell belanjaitem__username">{belanjaitem.username}</td>

                <td className="table__cell">
                    <button
                        className="icon-button table__button"
                        onClick={handleEdit}
                    >
                        <FontAwesomeIcon icon={faPenToSquare} />
                    </button>
                </td>
            </tr>
        )

    } else return null
}

const memoizedBelanjaitem = memo(Belanjaitem)

export default memoizedBelanjaitem
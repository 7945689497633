import { useGetKulkasitemsQuery } from "./kulkasitemsApiSlice"
import { useGetKulkassectionsQuery } from "../kulkassections/kulkassectionsApiSlice"
import Kulkasitem from "./Kulkasitem"
import Kulkassection from "../kulkassections/Kulkassection"
import Kulkassectionselectitem from "../kulkassections/Kulkassectionselectitem"
import useAuth from "../../../hooks/useAuth"
import useTitle from "../../../hooks/useTitle"
import PulseLoader from 'react-spinners/PulseLoader'
import { useState, useEffect } from "react"

const KulkasitemsList = () => {
    useTitle('techKulkasitems: Kulkasitems List')

    const { username, isManager, isAdmin } = useAuth()

    const [selectedKulkassectionId, setSelectedKulkassectionId]  = useState("")
    const [selectedKulkassectionName, setSelectedKulkassectionName]  = useState("")
    const [filteredItems, setFilteredItems] = useState([]); // State for filtered items

    const {
        data: kulkasitems,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetKulkasitemsQuery('kulkasitemsList', {
        pollingInterval: 15000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })

    const {
        data: kulkassections,
        isLoading: isLoadingKulkassections,
        isSuccess: isSuccessKulkassections,
        isError: isErrorKulkassections,
        error: errorKulkassections
    } = useGetKulkassectionsQuery('kulkassectionsList', {
        pollingInterval: 15000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })

    const handleSelectChange = (event) => {
        const selectedValue = event.target.value; // Get the value of the selected option
        const selectedText = event.target.options[event.target.selectedIndex].text; // Get the displayed text of the selected option
        setSelectedKulkassectionId(selectedValue)
        setSelectedKulkassectionName(selectedText)

        if (selectedValue === "all") {
            // Show all items but still filter by username
            const filtered = kulkasitems?.ids.filter(
                (id) => isManager || isAdmin || kulkasitems.entities[id].username === username
            );
            setFilteredItems(filtered || []);
        } else {
            // Filter by kulkassectionId and username
            const filtered = kulkasitems?.ids.filter(
                (id) =>
                    kulkasitems.entities[id].kulkassectionId === selectedValue &&
                    (isManager || isAdmin || kulkasitems.entities[id].username === username)
            );
            setFilteredItems(filtered || []);
        }
    }

    useEffect(() => {
        if (isSuccess) {
            // Initialize with all items if no filter is applied
            if (isManager || isAdmin) {
                setFilteredItems(kulkasitems?.ids || []);
            } else {
                setFilteredItems(kulkasitems?.ids.filter(kulkasitemId => kulkasitems?.entities[kulkasitemId].username === username))
            }
        }
    }, [isSuccess, kulkasitems]);

    
    let content

    if (isLoading) content = <PulseLoader color={"#FFF"} />
    if (isLoadingKulkassections) content = <PulseLoader color={"#FFF"} />

    if (isError) {
        content = <p className="errmsg">{error?.data?.message}</p>
    }
    if (isErrorKulkassections) {
        content = <p className="errmsg">{errorKulkassections?.data?.message}</p>
    }

    // Only if both RTK hook success is true, if salah satu false harusnya ketangkep di atas
    if (isSuccess && isSuccessKulkassections) {
        const { ids, entities } = kulkasitems
        const { ids: kulkassectionIds, entities: kulkassectionEntities } = kulkassections

        // tepat sebelum di render semua data di filter lagi dengan fungsi filter berdasarkan username
        // kalau tidak dipengaruhi username, bagian ini else nya bisa di buat sama dengan bagian true nya

        // filteredIds adalah kulkasitems
        let filteredIds
        if (isManager || isAdmin) {
            filteredIds = [...ids]
        } else {
            filteredIds = ids.filter(kulkasitemId => entities[kulkasitemId].username === username)
        }

        let filteredKulkassectionIds
        if (isManager || isAdmin) {
            filteredKulkassectionIds = [...kulkassectionIds]
        } else {
            // filteredKulkassectionIds = kulkassectionIds.filter(kulkasitemId => kulkassectionEntities[kulkasitemId].username === username)
            filteredKulkassectionIds = [...kulkassectionIds]
        }

        // const tableContent = ids?.length && filteredIds.map(kulkasitemId => <Kulkasitem key={kulkasitemId} kulkasitemId={kulkasitemId} />)
        const tableContent =
        filteredItems?.length &&
        filteredItems.map((kulkasitemId) => (
            <Kulkasitem key={kulkasitemId} kulkasitemId={kulkasitemId} />
        ));
        
        const tableContentKulkassections = kulkassectionIds?.length && filteredKulkassectionIds.map(kulkassectionId => <Kulkassection key={kulkassectionId} kulkassectionId={kulkassectionId} />)
        const selectContentKulkassections = kulkassectionIds?.length && filteredKulkassectionIds.map(kulkassectionId => <Kulkassectionselectitem key={kulkassectionId} kulkassectionId={kulkassectionId} />)

        content = (
            <>
                <table className="table table--kulkasitems">
                    <thead className="table__thead">
                        <tr>
                            <th scope="col" className="table__th kulkasitem__created">_id</th>
                            <th scope="col" className="table__th kulkasitem__created">name</th>
                            <th scope="col" className="table__th kulkasitem__updated">jumlah</th>
                            <th scope="col" className="table__th kulkasitem__title">unit</th>
                            <th scope="col" className="table__th kulkasitem__username">kulkassectionId</th>
                            <th scope="col" className="table__th kulkasitem__status">Username</th>
                            <th scope="col" className="table__th kulkasitem__edit">Edit</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableContent}
                    </tbody>
                </table>

                <div className="mt-10">
                    <div>
                        <span>Kulkas Section List</span>
                    </div>
                    <table className="table table--kulkasitems">
                        <thead className="table__thead">
                            <tr>
                                <th scope="col" className="table__th kulkassection__created">_id</th>
                                <th scope="col" className="table__th kulkassection__created">name</th>
                                <th scope="col" className="table__th kulkassection__updated">description</th>
                                <th scope="col" className="table__th kulkassection__status">Username</th>
                                <th scope="col" className="table__th kulkassection__edit">Edit</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tableContentKulkassections}
                        </tbody>
                    </table>

                </div>
                <div className="mt-10">
                    <div>
                        <span>Kulkas Section Dropdown (Select Options)</span>
                    </div>
                    <div>
                        <span>Anda memilih {selectedKulkassectionId} dengan nama {selectedKulkassectionName}</span>
                    </div>
                    <select id="options-select" className="rounded-md" value={selectedKulkassectionId} onChange={handleSelectChange}>
                        <option value="" disabled>--Select Kulkas Section--</option>
                        <option value="all">All Kulkas Sections</option>
                        {selectContentKulkassections}
                    </select> 
                </div>
            </>
        )
    }

    return content
}
export default KulkasitemsList
import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit";
import { apiSlice } from "../../../app/api/apiSlice"

const kulkassectionsAdapter = createEntityAdapter({
    sortComparer: (a, b) => (a.completed === b.completed) ? 0 : a.completed ? 1 : -1
})

const initialState = kulkassectionsAdapter.getInitialState()

export const kulkassectionsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getKulkassections: builder.query({
            query: () => ({
                url: '/kulkassections',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                },
            }),
            transformResponse: responseData => {
                const loadedKulkassections = responseData.map(kulkassection => {
                    kulkassection.id = kulkassection._id
                    return kulkassection
                });
                return kulkassectionsAdapter.setAll(initialState, loadedKulkassections)
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        { type: 'Kulkassection', id: 'LIST' },
                        ...result.ids.map(id => ({ type: 'Kulkassection', id }))
                    ]
                } else return [{ type: 'Kulkassection', id: 'LIST' }]
            }
        }),
        addNewKulkassection: builder.mutation({
            query: initialKulkassection => ({
                url: '/kulkassections',
                method: 'POST',
                body: {
                    ...initialKulkassection,
                }
            }),
            invalidatesTags: [
                { type: 'Kulkassection', id: "LIST" }
            ]
        }),
        updateKulkassection: builder.mutation({
            query: initialKulkassection => ({
                url: '/kulkassections',
                method: 'PATCH',
                body: {
                    ...initialKulkassection,
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Kulkassection', id: arg.id }
            ]
        }),
        deleteKulkassection: builder.mutation({
            query: ({ id }) => ({
                url: `/kulkassections`,
                method: 'DELETE',
                body: { id }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Kulkassection', id: arg.id }
            ]
        }),
    }),
})

export const {
    useGetKulkassectionsQuery,
    useAddNewKulkassectionMutation,
    useUpdateKulkassectionMutation,
    useDeleteKulkassectionMutation,
} = kulkassectionsApiSlice

// returns the query result object
export const selectKulkassectionsResult = kulkassectionsApiSlice.endpoints.getKulkassections.select()

// creates memoized selector
const selectKulkassectionsData = createSelector(
    selectKulkassectionsResult,
    kulkassectionsResult => kulkassectionsResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllKulkassections,
    selectById: selectKulkassectionById,
    selectIds: selectKulkassectionIds
    // Pass in a selector that returns the kulkassections slice of state
} = kulkassectionsAdapter.getSelectors(state => selectKulkassectionsData(state) ?? initialState)
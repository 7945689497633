import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons"
import { useNavigate } from 'react-router-dom'
import { useGetKulkassectionsQuery } from './kulkassectionsApiSlice'
import { memo } from 'react'

const Kulkassectionselectitem = ({ kulkassectionId }) => {

    const { kulkassection } = useGetKulkassectionsQuery("kulkassectionsList", {
        selectFromResult: ({ data }) => ({
            kulkassection: data?.entities[kulkassectionId]
        }),
    })

    const navigate = useNavigate()

    if (kulkassection) {
        const created = new Date(kulkassection.createdAt).toLocaleString('en-US', { day: 'numeric', month: 'long' })

        const updated = new Date(kulkassection.updatedAt).toLocaleString('en-US', { day: 'numeric', month: 'long' })

        const handleEdit = () => navigate(`/dash/kulkassections/${kulkassectionId}`)



        return (
            <>
                <option value={kulkassection._id}>{kulkassection.name}</option>
            </>
        )

    } else return null
}

const memoizedKulkassectionselectitem = memo(Kulkassectionselectitem)

export default memoizedKulkassectionselectitem